import React, { useEffect, useState } from "react";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import {
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";
import { Helmet } from "react-helmet";
import {
  GetClientsAPICall,
  AssignClientAPICall,
  GetStagesAPICall,
  ListUsersAPICall,
  permanentDeleteClientAPICall,
} from "../../Utils/APICalls";
import { IClientsData } from "../../Utils/dtos";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import { IClientData, IDeleteUser, IEditUser } from "../../Utils/dtos";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "../../components/Tabs/Tabs";
import ClientDetailspopup from "../../components/Modals/ClientDetailspopup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import stagesData from "../../Utils/stages.json";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import FollowUpsModal from "../../components/Modals/FollowUps/FollowUpsModal";
import HistoryIcon from "@mui/icons-material/History";
import { useDebounce } from "use-debounce";
import ClientLogsModal from "../../components/Modals/ClientLogsPopUpModal/ClientLogsModalPopUp";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { ADMINLOGINLINK } from "../../Utils/constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classes from "./NewKyc.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filters from "../../components/Filters/Filters";
import { confirmAlert } from "react-confirm-alert";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };

const Data: any = stagesData;

const mappingValues: any = {
  Today: "today",
  All: "all",
  "Non Esigned": "non_esigned",
  "Approval Pending": "approval_pending",
  Rejected: "rejected",
  Approved: "approved",
  "BO Synced": "bo_synced",
  Deleted: "deleted",
  "Account Opened": "account_opened",
};

const mappingStagesData: any = {
  pan: "Pan details",
  kra_or_aadhaar: "KRA verification or Digi-locker",
  nominee: "Nominee ",
  esign: "Esign section",
  segment: "Trading preferences",
  customer_details: "Personal details",
  bank: "Bank details",
  ipv: "Identification verification",
  document_upload: "Upload documents",
  t_and_c: "Terms and conditions",
};
export default function NewKYCPage() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("Today");
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [clientDetailsPopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
  });
  const [clientTimeStamp, setClientTimeStamp] = useState({
    MobileVerifiedTS: "",
    EmailVerifiedTS: "",
  });
  const [clientFollowUpPopup, setclientFollowPopup] = useState({
    status: false,
    data: "",
  });
  const [clientLogsPopup, setclientLogsPopup] = useState({
    status: false,
    data: {},
  });
  const [clientId, setClientId] = useState("");
  const [userName, setUserName] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [stagesData, setStagesData] = useState<any>();
  const [users, setusers] = useState<any>();
  const [searchData, setSearchData] = useState("");
  const [stageFilterData, setStageFilterData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [tableRefreshFilters, settablerefreshfilters] = useState("");
  const [dateRangeFilterData, setDateRangeFilterData] = useState<any>({
    fromDate: dayjs().subtract(2, "month"),
    toDate: dayjs(new Date()),
  });
  const [datevalue, setdateValue] = useState<DateRange<Dayjs | any>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  const [stageFilter, setStageFilter] = useState<any>("");
  const [userId, setUserId] = useState<any>("");
  const [recordsCount, setRecordsCount] = useState(0);
  const [stageName, setStageName] = useState<any>("today");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [rotate, setRotate] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(true);
  const isAdmin = sessionStorage.getItem("isAdmin");

  function formatDateChange(inputDate: any) {
    const dateParts = inputDate.split("-"); // Split the input date into parts
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Rearrange the parts in the desired format
    return formattedDate;
  }

  const columnsAccountOppened = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <Tooltip title={"Click to Login"}>
          <b
            style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
            onClick={() => {
              handleAdminLogintoClientAccount(row);
            }}
          >
            {row.appno}
          </b>
        </Tooltip>
      ),
    },

    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   if(activitySort === "ASC"){
          //     setactivitySort("DESC")
          //   } else if(activitySort === "DESC"){
          //     setactivitySort("ASC")
          //   }
          // }}
        >
          Stage
        </b>
      ),
      selector: "stage",
      sortable: true,
      // width: "90px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Source</b>,
      selector: "sources",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.source}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   console.log("sourceId");
          // }}
        >
          S Id
        </b>
      ),
      selector: "source_id",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.source_id}</b>,
      width: "70px",
    },
    // {
    //   name: (
    //     <b
    //       style={titletextStyle}
    //       onClick={() => {
    //         if (sortactivity === "ASC") {
    //           setsortactivity("DESC");
    //         } else if (sortactivity === "DESC") {
    //           setsortactivity("ASC");
    //         }
    //       }}
    //     >
    //       Activity
    //     </b>
    //   ),
    //   selector: "pending_days",
    //   sortable: false,
    //   width: "90px",
    //   cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    // },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   console.log("sourceId");
          // }}
        >
          Opened On
        </b>
      ),
      selector: "account_open_date",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {!row.account_open_date
            ? ""
            : formatDateChange(row.account_open_date)}
        </b>
      ),
      // width: "100px",
    },
    {
      name: <b style={titletextStyle}>MF AC TS</b>,
      selector: "mfaccountopenedts",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {!row.mfaccountopenedts
            ? "-"
            : formatDateChange(row.mfaccountopenedts.slice(0, 10))}
        </b>
      ),
      // width: "100px",
    },
    {
      name: <b style={titletextStyle}>MF Status</b>,
      selector: "mfaccountopenedts",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.mfaccountopenedstatus ? row.mfaccountopenedstatus : "-"}
        </b>
      ),
      // width: "100px",
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      // width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user
                ? users?.filter((a: any) => a.admin_id === row.handling_user)[0]
                    ?.full_name
                : ""}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {stageName === "approved" ? (
            <Tooltip title="KRA Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}

          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "#383838" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title={row.handling_user === "" ? "Asign User" : "FollowUp"}>
            <IconButton
              onClick={() => {
                handleFollowUpData(row);
              }}
             
            >
              <AddIcCallIcon
                sx={{ fill: row.followup_date === null ? "red" : "" }}
              />
            </IconButton>
          </Tooltip> */}
        </b>
      ),
    },
  ];
  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <Tooltip title={"Click to Login"}>
          <b
            style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
            onClick={() => {
              handleAdminLogintoClientAccount(row);
            }}
          >
            {row.appno}
          </b>
        </Tooltip>
      ),
    },

    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "90px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   if(activitySort === "ASC"){
          //     setactivitySort("DESC")
          //   } else if(activitySort === "DESC"){
          //     setactivitySort("ASC")
          //   }
          // }}
        >
          Stage
        </b>
      ),
      selector: "stage",
      sortable: true,
      // width: "90px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.logs?.RejectedTS ? (
            <>
              <span style={{ color: "red" }}>Rej-</span>
              {Data.hasOwnProperty(row.current_stage) &&
                Data[row.current_stage]}
            </>
          ) : (
            Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]
          )}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Source</b>,
      selector: "sources",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.source}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   console.log("sourceId");
          // }}
        >
          S Id
        </b>
      ),
      selector: "source_id",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.source_id}</b>,
      width: "70px",
    },
    // {
    //   name: (
    //     <b
    //       style={{ ...titletextStyle, display: "flex", alignItems: "center" }}
    //       onClick={() => {
    //         setRotate(!rotate);
    //         if (sortactivity === "ASC") {
    //           setsortactivity("DESC");
    //         } else if (sortactivity === "DESC") {
    //           setsortactivity("ASC");
    //         }
    //       }}
    //     >
    //       Activity{" "}
    //       <ArrowDropDownIcon
    //         sx={{
    //           transform: rotate ? "rotate(180deg)" : "",
    //           transition: "0.5s ease",
    //         }}
    //       />
    //     </b>
    //   ),
    //   selector: "pending_days",
    //   sortable: false,
    //   width: "90px",
    //   cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    // },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      // width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user
                ? users?.filter((a: any) => a.admin_id === row.handling_user)[0]
                    ?.full_name
                : ""}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {stageName === "approved" ? (
            <Tooltip title="KRA Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}

          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "#383838" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={row.handling_user === "" ? "Asign User" : "FollowUp"}>
            <IconButton
              onClick={() => {
                handleFollowUpData(row);
              }}
            >
              <AddIcCallIcon
                sx={{ fill: row.followup_date === null ? "red" : "" }}
              />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];
  const columnsForDeletedAndRejected = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <Tooltip title={"Click to Login"}>
          <b
            style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
            onClick={() => {
              handleAdminLogintoClientAccount(row);
            }}
          >
            {row.appno}
          </b>
        </Tooltip>
      ),
    },

    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: <b style={titletextStyle}>Stage</b>,
      selector: "stage",
      sortable: true,
      // width: "80px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]}
        </b>
      ),
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   console.log("sourceId");
          // }}
        >
          S Id
        </b>
      ),
      selector: "source_id",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.source_id}</b>,
      // width: "100px",
    },
    // {
    //   name: (
    //     <b
    //       style={titletextStyle}
    //       onClick={() => {
    //         if (sortactivity === "ASC") {
    //           setsortactivity("DESC");
    //         } else if (sortactivity === "DESC") {
    //           setsortactivity("ASC");
    //         }
    //       }}
    //     >
    //       Activity
    //     </b>
    //   ),
    //   selector: "pending_days",
    //   sortable: false,
    //   // width: "90px",
    //   cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    // },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      // width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer", color: "#555555" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user
                ? users?.filter((a: any) => a.admin_id === row.handling_user)[0]
                    ?.full_name
                : ""}
            </b>
          )}
        </>
      ),
    },

    {
      name: <b style={titletextStyle}>Reason</b>,
      selector: "reason",
      sortable: true,

      cell: (row: any) => (
        <b style={contenttextStyle}>
          {(row.rejected_reason && row.rejected_reason) ||
            (!row.rejected_reason && row.deleted_reason)}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "130px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {stageName === "approved" ? (
            <Tooltip title="Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
                if (row.rejected_reason) {
                  setRejectedReason(row.rejected_reason);
                }
                if (row.deleted_reason) {
                  setDeletedReason(row.deleted_reason);
                }
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "#383838" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title={row.handling_user === "" ? "Asign User" : "FollowUp"}>
            <IconButton
              onClick={() => {
                handleFollowUpData(row);
              }}
           
            >
              <AddIcCallIcon
                sx={{ fill: row.followup_date === null ? "red" : "" }}
              />
            </IconButton>
          </Tooltip> */}
        </b>
      ),
    },
  ];
  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };
  //  console.log("columns", columns);

  function formatDatePayload(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  function formatDatePayloadFilters(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  useEffect(() => {
    GetStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setStagesData(responseData.data.stages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    ListUsersAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setusers(responseData.data.data);
      })
      .catch((error: any) => {});
  }, []);

  // console.log("userFilterData", userFilterData);

  useEffect(() => {
    setloading(true);
    setRecordsCount(0);
    const delayDebounceFn = setTimeout(() => {
      setdata([]);
      getClients(
        pagenumber,
        perPage,
        searchData,
        userFilterData,
        dateRangeFilterData.fromDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.fromDate),
        dateRangeFilterData.toDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.toDate),
        stageFilterData,
        stageName
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedTab,
    searchData,
    stageFilterData,
    userFilterData,
    dateRangeFilterData,
    stageName,
    pagenumber,
    sortactivity,
  ]);

  const getClients = (
    page: number,
    perpage: number,
    query: any,
    filter_user: any,
    filter_date_from: any,
    filter_date_to: any,
    filter_stage: any,
    // sortactivity:any,
    type?: any
  ) => {
    setloading(true);
    const reqObj: IClientsData = {
      page_no: page,
      page_size: perpage,
      query: query,
      type: type ? type : stageName,
      filter_user: filter_user,
      filter_date_from: filter_date_from,
      filter_date_to: filter_date_to,
      filter_stage: filter_stage,
      sort_activity: sortactivity,
    };

    GetClientsAPICall(reqObj)
      .then((response: any) => {
        setloading(false);
        const responseData = response.data;
        // console.log("responseData.data.", responseData.data.data);
        setdata(responseData.data.data);

        // console.log(responseData.total_records);
        setTotalRows(Math.ceil(responseData.data.total_count / 20));

        if (responseData.data.total_count) {
          setRecordsCount(responseData.data.total_count);
        } else {
          setRecordsCount(0);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const handleFollowUpData = (data: any) => {
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      setclientFollowPopup({
        status: true,

        data: data._id,
      });
    }
  };
  const handleClientLogs = (data: any) => {
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      //  console.log(data.logs);
      setclientLogsPopup({
        status: true,
        data: data.logs,
      });
    }
  };
  const handleCloseClientLogsPopup = () => {
    setclientLogsPopup({
      status: false,
      data: "",
    });
  };
  const handleSearchChange = (data: any) => {
    setSearchData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };
  const handleDateRangeChange = (data: any) => {
    // console.log("dateData", data);
    setDateRangeFilterData({
      fromDate: !data[0].$d ? "" : formatDate(data[0].$d),
      toDate: !data[1].$d ? "" : formatDate(data[1].$d),
    });
  };
  const handleStageChange = (data: any) => {
    setPageNumber(1);
    setpaginationCount(1);
    setStageFilterData(data);

    // getClients(1, perPage, data);
  };
  const handleUserChange = (data: any) => {
    // console.log("dataww",data.admin_id)
    setPageNumber(1);
    setUserFilterData(data.admin_id);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleAssign = (data: any) => {
    if (isAdmin === "false") {
      const obj: any = {
        client_id: data._id,
        admin_id: sessionStorage.getItem("userId"),
      };
      AssignClientAPICall(obj).then((response: any) => {
        //  console.log("response", response);
        alert("Assigned to me");
        getClients(1, perPage, "", "", "", "", "", "");
      });
    } else {
      setOpen(true);
      setClientId(data._id);
    }
  };
  const handleViewClientDetails = (data: any) => {
    // console.log("clientData", data);
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      setclientDetailsPopup({
        status: true,
        data: data._id,
      });
      setClientId(data._id);
      setClientTimeStamp({
        MobileVerifiedTS: data.logs.MobileVerifiedTS,
        EmailVerifiedTS: data.logs.EmailVerifiedTS,
      });
    }
  };
  const handleAdminLogintoClientAccount = (data: any) => {
    //  console.log(data);
    // window.open(
    //   `http://localhost:3000?client_id=${
    //     data.client_id
    //   }&user_id=${sessionStorage.getItem("userId")}`,
    //   "_blank"
    // );
    //console.log("data", data.completed_ts);
    // if (!data.completed_ts) {
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      window.open(
        `${ADMINLOGINLINK}?client_id=${
          data._id
        }&user_id=${sessionStorage.getItem("userId")}`,
        "_blank"
      );
    }
    // }
  };
  const handleCloseClientDetailsPopup = (data: any) => {
    sessionStorage.removeItem(`${clientDetailsPopup.data}_aadhaar`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_ipv`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_signature`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_pancard`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_income_proof`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_e_sign_pdf`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_kra_upload`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_bank_proof`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof1`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof2`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof3`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_cmr`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_e_sign_pdf`);
    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
    });

    // getClients(1, perPage, "", "", "", "", "");

    if (data === "Deleted") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Deleted Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Approved") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Approved Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Rejected") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Rejected Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else {
      setClientMessage(""); // Reset the message for other cases
    }
  };
  const handleCloseClientFollowUpPopup = (data: any) => {
    //  console.log("data", data);
    setclientFollowPopup({
      status: false,
      data: "",
    });
    getClients(
      pagenumber,
      perPage,
      searchData,
      userFilterData,
      dateRangeFilterData.fromDate === ""
        ? ""
        : formatDatePayload(dateRangeFilterData.fromDate),
      dateRangeFilterData.toDate === ""
        ? ""
        : formatDatePayload(dateRangeFilterData.toDate),
      stageFilterData,
      stageName
    );
  };
  const handlePermanentDeleteClient = () => {
    //  console.log("data", data);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <DeleteOutlineRoundedIcon />
            <p>Are you sure, client will be permanently deleted</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  let reqObj: any = {
                    user_id: clientId,
                  };

                  permanentDeleteClientAPICall(reqObj)
                    .then((response: any) => {
                      const responseData = response.data;
                      getClients(1, perPage, "", "", "", "", "", "");
                      setClientMessage("Client Deleted Successfully");
                      setclientDetailsPopup({
                        status: false,
                        data: "",
                      });
                      setTimeout(() => {
                        setClientMessage("");
                      }, 5000);
                    })
                    .catch((error) => {});
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const handleAssignePopUpClose = () => {
    setOpen(false);
  };
  const handleAssigneID = (data: any) => {
    setOpen(false);

    const obj: any = {
      client_id: clientId,
      admin_id: data,
    };
    AssignClientAPICall(obj)
      .then((response: any) => {
        // alert(`Assigned to me ${response.data.data.User_name}`);
        setUserName(response.data.data.User_name);
        getClients(
          pagenumber,
          perPage,
          searchData,
          userFilterData,
          dateRangeFilterData.fromDate === ""
            ? ""
            : formatDatePayload(dateRangeFilterData.fromDate),
          dateRangeFilterData.toDate === ""
            ? ""
            : formatDatePayload(dateRangeFilterData.toDate),
          stageFilterData,
          ""
        );
      })
      .catch((error: any) => {});
  };
  const handleSubmitDetailsPopup = (data: any) => {
    //  console.log("data", data);
  };
  const handleSubmitFollowUpDataPopup = (data: any) => {
    //  console.log("data", data);
  };
  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    getClients(page, newPerPage, "", "", "", "", "", "");
  };

  const handleSelectApprovedChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };

  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  const handleClearFilters = () => {
    setdateValue([null, null]);
    setSearchData("");
    setUserId("");
    setUserFilterData("");
    handleDateRangeChange(["", ""]);
    setStageFilter("");
    setStageFilterData("");
  };

  // console.log("mappingValues[selectedTab]", stageName);

  return (
    <div style={{ marginTop: "6px", width: "100%" }}>
      <Helmet>
        <title>New KYC </title>
        <meta name="New KYC" content="New KYC" />
      </Helmet>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className={classes.category}>
          <FormControl sx={{ width: "230px" }} size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stageName}
              name="selectStage"
              label="Category"
              onChange={handleSelectChange}
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="non_esigned">Non Esigned</MenuItem>
              <MenuItem value="approval_pending">Approval Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="bo_synced">Bo Synced</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
              <MenuItem value="final">Final</MenuItem>
              <MenuItem value="account_opened">Account Opened</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* {datevalue &&
          datevalue[0] &&
          datevalue[0]?.$d &&
          datevalue &&
          datevalue[1] &&
          datevalue[1]?.$d  && ( */}
        <div className={classes.chipsSection}>
          {/* <p className={classes.appliedFilters}>Applied Filters</p> */}
          {datevalue && datevalue[0] && datevalue[0]?.$d && (
            <div className={classes.chips}>
              From Date
              <div>
                <Chip
                  label={
                    !datevalue[0].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[0]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue(["", ""]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}
          {datevalue && datevalue[1] && datevalue[1]?.$d && (
            <div className={classes.chips}>
              To Date
              <div>
                <Chip
                  label={
                    !datevalue[1].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[1]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue([null, null]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}
          {stageFilter && (
            <div className={classes.chips}>
              Stage
              <div>
                <Chip
                  label={mappingStagesData[stageFilter]}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setStageFilter("");
                    setStageFilterData("");
                  }}
                />
              </div>
            </div>
          )}
          {userId && (
            <div className={classes.chips}>
              User
              <div>
                <Chip
                  label={userId?.full_name}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setUserId("");
                    setUserFilterData("");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* )} */}

        <div className={classes.emailListHeader}>
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
          >
            Clear
          </Button>

          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: "#8B93FF" }} />
            </div>
            <input
              type="text"
              className={classes.inputBox}
              placeholder="Search...."
              value={searchData}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </div>

          <Button
            variant="outlined"
            startIcon={<FilterListIcon style={{ color: "#8B93FF" }} />}
            onClick={() => setOpenFilters(true)}
            sx={{
              textTransform: "none",
              color: "#000",
              border: "1px solid #DDDCE1",
              "&:hover": {
                color: "#000",
                border: "1px solid #DDDCE1",
              },
            }}
          >
            Filters
          </Button>
          {openFilters && (
            <Filters
              selectedTab={selectedTab}
              datevalue={datevalue}
              setdateValue={setdateValue}
              handleDateRangeChange={handleDateRangeChange}
              stagesData={stagesData}
              stageFilter={stageFilter}
              setStageFilter={setStageFilter}
              handleStageChange={handleStageChange}
              userId={userId}
              isAdmin={isAdmin}
              setUserId={setUserId}
              handleUserChange={handleUserChange}
              users={users}
              handleCloseFilters={handleCloseFilters}
            />
          )}
        </div>
      </div>
      <div>
        <DataTableComponent
          columns={
            stageName === "deleted" || stageName === "rejected"
              ? columnsForDeletedAndRejected
              : stageName === "account_opened"
              ? columnsAccountOppened
              : columns
          }
          data={data}
          loading={loading}
          pagination={true}
          paginationServer={true}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          totalRows={totalRows}
          selectedTab={stageName}
          paginationCount={paginationCount}
          recordsCount={recordsCount}
        />
      </div>

      {clientDetailsPopup.status && (
        <ClientDetailspopup
          data={clientDetailsPopup.data}
          handleSubmitData={handleSubmitDetailsPopup}
          open={clientDetailsPopup.status}
          handleClose={handleCloseClientDetailsPopup}
          rejectedReason={rejectedReason}
          deletedReason={deletedReason}
          selectedTabNew={stageName}
          handlePermanentDeleteClient={handlePermanentDeleteClient}
          clientTimeStamp={clientTimeStamp}
          stageName={stageName}
        />
      )}
      {clientFollowUpPopup.status && (
        <FollowUpsModal
          data={clientFollowUpPopup.data}
          handleSubmitData={handleSubmitFollowUpDataPopup}
          open={clientFollowUpPopup.status}
          handleClose={handleCloseClientFollowUpPopup}
        />
      )}
      {clientLogsPopup.status && (
        <ClientLogsModal
          open={clientLogsPopup.status}
          handleClose={handleCloseClientLogsPopup}
          handleSubmitData={undefined}
          data={clientLogsPopup.data}
        />
      )}

      <SucessSnackBar message={clientMessage} />
      <AssignUser
        open={open}
        handleClose={handleAssignePopUpClose}
        assigneID={handleAssigneID}
        userData={users}
      />
    </div>
  );
}
