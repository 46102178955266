import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IApproveClientRequestEmailMobileUpdate,
  IClientDataBankUpdate,
  IDeleteClientBankUpdate,
  IRejectApplicationBankUpdate,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Box,
  IconButton,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveApplicationNomineeUpdateAPICall,
  DeleteClientNomineeUpdateAPICall,
  GetClientDataNomineeUpdateAPICall,
  GetRekycStagesAPICall,
  GetRequestListNomineeUpdateAPICall,
  RejectApplicationNomineeUpdateAPICall,
} from "../../../Utils/APICalls";
import Styles from "./NomineeUpdateDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import NomineeUpdateBase64ToImage from "../../NomineeUpdateBase64/NomineeUpdateBase64";
import NomineeUpdateReject from "../RejectComponent/NomineeUpdateReject";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function NomineeUpdateDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();
  const [selectedTab, setselectedTab] = useState<string>("Esign");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState([
    "Esign",
    "Nominee 1",
    "Nominee 2",
    "Nominee 3",
  ]);
  const [docType, setDocType] = useState("e_sign_pdf");

  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    getDetails();

    // GetRekycStagesAPICall()
    //   .then((response: any) => {
    //     const responseData = response.data;
    //     const sortedStages = responseData.sort(
    //       (a: any, b: any) => a.Sequence - b.Sequence
    //     );

    //     setStagesData(sortedStages);
    //   })
    //   .catch((error: any) => {
    //     //  console.log("error", error);
    //   });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
    sessionStorage.removeItem(`${data}_nominee_proof1`);
    sessionStorage.removeItem(`${data}_nominee_proof2`);
    sessionStorage.removeItem(`${data}_nominee_proof3`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: any = {
      user_id: data,
    };
    GetClientDataNomineeUpdateAPICall(obj)
      .then((response: any) => {
        console.log("responseclient", response.data.data);
        setClientData(response.data.data);
        // GetRequestList(response.data.client_data.ExistingDetails.ClientCode);
      })
      .catch((error: any) => {});
  };

  const GetRequestList = (clientcode: any) => {
    let obj: any = {
      client_code: clientcode,
    };

    GetRequestListNomineeUpdateAPICall(obj)
      .then((response: any) => {
        // console.log("requestlist", response.data.data[0]);
        setRequestList(response.data.data[0]);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: any = {
      user_id: data,
      rejected_reason: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectApplicationNomineeUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: any = {
      user_id: data,
    };
    setApproveLoading(true);
    ApproveApplicationNomineeUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);

    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
    if (tab === "Nominee 1") {
      setDocType("nominee_proof1");
    }
    if (tab === "Nominee 2") {
      setDocType("nominee_proof2");
    }
    if (tab === "Nominee 3") {
      setDocType("nominee_proof3");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: any = {
      user_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteClientNomineeUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const formatArrangeDOB = (inputDate: any) => {
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData?.ClientDetails &&
                  clientData?.ClientDetails.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason && stageName === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && stageName === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.MobileNumber?.toString()}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.Email}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Client Code :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.ClientCode}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      DpId :{" "}
                      <span className={Styles.content}>
                        {clientData?.ClientDetails &&
                          clientData?.ClientDetails?.dpid}
                      </span>
                    </p>
                  </div>
                  <p className={Styles.title}>
                    Status :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails?.AccountStatus}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Created At :{" "}
                      <span className={Styles.content}>
                        {clientData?.EventTimestamps?.created_ts === null
                          ? ""
                          : clientData?.EventTimestamps?.created_ts?.slice(
                              0,
                              10
                            )}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className={Styles.title}>
                      Completed At :{" "}
                      <span className={Styles.content}>
                        {clientData?.EventTimestamps?.completed_ts === ""
                          ? ""
                          : clientData?.EventTimestamps?.completed_ts?.slice(
                              0,
                              10
                            )}
                      </span>
                    </p>
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p className={Styles.title} style={{ fontSize: "18px" }}>
                      New Nominee Details :
                    </p>
                    {clientData &&
                      clientData?.NewDetails?.nominee_details &&
                      clientData?.NewDetails?.nominee_details.map(
                        (data: any, index: any) => {
                          return (
                            <>
                              <p
                                className={Styles.title}
                                style={{
                                  // textDecoration: "underline",
                                  background: "#d9e4fd",
                                  width: "100%",
                                  padding: "3px 20px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Nominee {index + 1}
                              </p>
                              <div>
                                <p className={Styles.title}>
                                  Name :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {" "}
                                    {data.FullName}
                                  </span>
                                </p>

                                <div>
                                  <p className={Styles.title}>
                                    RelationShip :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Relation Others : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipOthersValue}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Email :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Email}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Mobile :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Mobile}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Share Percentage :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.SharePercentage}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    DOB :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.DOB}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Nominee ID Proof :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.NomineeIDProof}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Nominee ID ProofNo : &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.NomineeIDProofNo}
                                    </span>
                                  </p>
                                </div>

                                <div>
                                  <p className={Styles.title}>
                                    Nominee Address :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Address}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Pincode :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Pincode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    City :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.City}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    State :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.State}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian FullName:{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianFullName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian DOB: &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianDOB}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Email: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianEmail}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Address: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddress}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian City: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressCity}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian PinCode: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressPinCode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian State: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressState}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Relation : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianRealtionWithNominee}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p className={Styles.title} style={{ fontSize: "18px" }}>
                      Existing Nominee Details :
                    </p>
                    {clientData &&
                      clientData?.ExistingDetails?.nominee_details &&
                      clientData?.ExistingDetails?.nominee_details.map(
                        (data: any, index: any) => {
                          return (
                            <>
                              <p
                                className={Styles.title}
                                style={{
                                  // textDecoration: "underline",
                                  background: "#d9e4fd",
                                  width: "100%",
                                  padding: "3px 20px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Nominee {index + 1}
                              </p>
                              <div>
                                <p className={Styles.title}>
                                  Name :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {" "}
                                    {data.FullName}
                                  </span>
                                </p>

                                <div>
                                  <p className={Styles.title}>
                                    RelationShip :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Relation Others : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipOthersValue}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Email :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Email}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Mobile :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Mobile}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Share Percentage :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.SharePercentage}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    DOB :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.DOB && formatArrangeDOB(data.DOB)}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Nominee ID Proof :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.NomineeIDProof}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Nominee ID ProofNo : &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.NomineeIDProofNo}
                                    </span>
                                  </p>
                                </div>
                                {/* <div>
                                  <p className={Styles.title}>
                                    Nominee RelationShip :{" "}
                                    <span className={Styles.content}>
                                      {data.NomineeRelationship}
                                    </span>
                                  </p>
                                </div> */}

                                <div>
                                  <p className={Styles.title}>
                                    Nominee Address :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Address}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Pincode :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Pincode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    City :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.City}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    State :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.State}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian FullName:{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianFullName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian DOB: &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianDOB &&
                                        formatArrangeDOB(data.GuradianDOB)}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Email: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianEmail}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Address: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddress}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian City: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressCity}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian PinCode: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressPinCode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian State: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressState}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Relation : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianRealtionWithNominee}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <NomineeUpdateBase64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {clientData?.BooleanValues.is_deleted === false && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientData?.BooleanValues.is_deleted && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />

              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {stageName !== "deleted" &&
                  stageName !== "rejected" &&
                  clientData?.BooleanValues?.is_rejected === false &&
                  clientData?.BooleanValues?.is_completed === true && (
                    <NomineeUpdateReject
                      stagesData={stagesData}
                      handleRejectClient={handleRejectClient}
                      rejectLoading={rejectLoading}
                      disabled={false}
                    />
                  )}

                {isAdmin === "true" && (
                  <div>
                    {stageName !== "deleted" &&
                      stageName !== "rejected" &&
                      clientData?.BooleanValues?.is_completed === true &&
                      clientData?.BooleanValues?.is_approved === false &&
                      clientData?.BooleanValues?.is_rejected === false &&
                      stageName !== "approved" && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          // disabled={
                          //   clientData?.BooleanValues &&
                          //   clientData?.BooleanValues.is_completed
                          //     ? false
                          //     : true
                          // }
                          disabled={false}
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                <ErrorSnackBar message={errorMessage} />
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
