import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  IApproveClientApplication,
  IClientData,
  IDeleteClient,
  IDeleteUser,
  IGeoJith,
  IKRARegister,
  IRejectClientApplication,
  IRekycApproveClientApplication,
  IRekycClientData,
  IRekycDeleteClient,
  IRekycRejectClientApplication,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  Modal,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveClientApplicationAPICall,
  DeletClientAPICall,
  DeletUserAPICall,
  GetClientDetailsAPICall,
  GetRekycStagesAPICall,
  GetRekycclientDataAPICall,
  GetStagesAPICall,
  RejectClientApplicationAPICall,
  RekycApproveClientApplicationAPICall,
  RekycDeleteClientAPICall,
  RekycRejectClientApplicationAPICall,
  geojithAPICall,
  kraRegisterAPICall,
} from "../../../Utils/APICalls";
import Styles from "./RekycClientDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import Base64ToImage from "../../Base64ToImage/Base64ToImage";
import Reject from "../RejectComponent/Reject";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import CheckedBox from "../../../assets/Images/CheckedBox.png";
import EmptyCheckBox from "../../../assets/Images/EmptyCheckBox.png";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { unset } from "lodash";
import RekycBase64ToImage from "../../RekycBase64toImage/RekycBase64toImage";
import RekycReject from "../RejectComponent/RekycReject";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function RekycClientDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();
  const [clientBankData, setClientBankData] = useState<any>({
    BankName: "",
    IFSCCode: "",
    Micr: "",
    AcNo: "",

    NameAsInBank: "",
  });
  const [KRAData, setKRAData] = useState<any>();
  const [AadhaarData, setAadhaarData] = useState<any>({
    AadhaarNum: "",
    ClientAadharName: "",
    DobOnAadhaar: "",
    AadhaarAddress: "",
    AadhaarGender: "",
  });
  const [tradingPreference, setTradingPrefernce] = useState<any>({
    mutual_funds: "",
    equity: "",
    futures_and_options: "",
    currency: "",
    commodity: "",
  });
  const [selectedTab, setselectedTab] = useState<string>("Pan");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState(["Pan", "Photo", "Esign"]);
  const [docType, setDocType] = useState("pancard");
  const [stages, setStages] = useState<any>();
  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [geojithLoading, setgeojithLoading] = useState(false);
  const [kraUploadLoading, setKraUploadLoading] = useState(false);

  const [nomineeDetails, setNomineeDetails] = useState<any>();

  const name = clientData && clientData.ClientDetails.ClientName;
  const AadhaarXMLData = clientData && clientData?.Aadhaar?.aadhaar_xml;

  // console.log("name")

  const removeStages = (stages: any) => {
    const stagesToRemove = ["Terms and Conditions", "Segment Details"];

    const filteredStages = stages.filter(
      (stage: any) => !stagesToRemove.includes(stage.Stage_Name)
    );
    return filteredStages;
  };

  useEffect(() => {
    getDetails();

    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;

        setStagesData(responseData.data.stages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_aadhar_client_photo`);
    sessionStorage.removeItem(`${data}_webcam_client_photo`);
    sessionStorage.removeItem(`${data}_pancard`);
    sessionStorage.removeItem(`${data}_income_proof`);
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: IRekycClientData = {
      user_id: data,
    };
    GetRekycclientDataAPICall(obj)
      .then((response: any) => {
        // console.log("response.data.data", response.data.data);
        setClientData(response.data.data);
        setNomineeDetails(response.data.data.NomineeDetails);
        const filteredTrade = response.data.data.TradingPreference.filter(
          (a: any) => a.label === "futures_and_options"
        )[0];
        if (response.data.data.BooleanValues.is_aadhaar_fetched === true) {
          if (filteredTrade.opted === true) {
            setTabLabels(["Pan", "Photo", "Aadhaar", "IncomeProof", "Esign"]);
          } else {
            setTabLabels(["Pan", "Photo", "Aadhaar", "Esign"]);
          }
        } else if (
          response.data.data.BooleanValues.is_aadhaar_fetched === false
        ) {
          if (filteredTrade.opted === true) {
            setTabLabels(["Pan", "Photo", "IncomeProof", "Esign"]);
          } else {
            setTabLabels(["Pan", "Photo", "Esign"]);
          }
        }

        // if (response.data.data.BankDetails.length > 0) {
        //   setClientBankData({
        //     BankName: response.data.data.BankDetails[0].BankName,
        //     IFSCCode: response.data.data.BankDetails[0].Ifsc,
        //     Micr: response.data.data.BankDetails[0].Micr,
        //     AcNo: response.data.data.BankDetails[0].BankAccNum,

        //     NameAsInBank: response.data.data.BankDetails[0].CustomerNameInBank,
        //   });
        // }

        if (response.data.data.KraDetails.is_fetched) {
          setKRAData(response.data.data.KraDetails);
        }
        if (response.data.ClientDetails.AadhaarDetails) {
          setAadhaarData({
            AadhaarNum: response.data.data.AadhaarDetails.AadhaarNum,
            ClientAadharName:
              response.data.data.AadhaarDetails.ClientAadharName,
            DobOnAadhaar: response.data.data.AadhaarDetails.DobOnAadhaar,
            AadhaarAddress: response.data.data.AadhaarDetails.AadhaarAddress,
            AadhaarGender: response.data.data.AadhaarDetails.AadhaarGender,
          });
        }
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: any = {
      user_id: data,
      stage: reject.selectStage,
      rejected_reason: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RekycRejectClientApplicationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: any = {
      user_id: data,
    };
    setApproveLoading(true);
    RekycApproveClientApplicationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleKRAUpload = () => {
    let obj: IKRARegister = {
      client_id: data,
    };
    setKraUploadLoading(true);
    kraRegisterAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setKraUploadLoading(false);
        setSuccessMessage("KRA Registration is Successfully");

        // handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setKraUploadLoading(false);
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);
    if (tab === "Aadhaar") {
      setDocType("aadhar_client_photo");
    }

    if (tab === "Photo") {
      setDocType("webcam_client_photo");
    }

    if (tab === "IncomeProof") {
      setDocType("income_proof");
    }
    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
    if (tab === "Pan") {
      setDocType("pancard");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: IRekycDeleteClient = {
      user_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    RekycDeleteClientAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };

  const handleDownloadXml = () => {
    const blob = new Blob([AadhaarXMLData], { type: "application/xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.xml`;
    a.click();

    URL.revokeObjectURL(url);
  };

  const formatArrangeDOB = (inputDate: any) => {
    const parts = inputDate.split("/");
    const formattedDate = `${parts[0]}-${parts[1]}-${parts[2]}`;
    return formattedDate;
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData && clientData.ClientDetails.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                {rejectedReason && stageName === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && stageName === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.MobileNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                        clientData.ClientDetails.Email?.toLowerCase()}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT ID :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.ClientCode}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    PAN :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.PANNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    DOB :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                        clientData.ClientDetails.DateOfBirth?.slice(0, 10)}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    KRA Upload At :{" "}
                    <span className={Styles.content}>
                      {/* {clientData &&
                        clientData.client_details.KrauploadedTS.slice(0, 16)} */}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Demat No :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.DematNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Proof Source :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                      clientData.BooleanValues.is_aadhaar_fetched === false
                        ? "KRA"
                        : "Digilocker"}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Gender :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.PersonalDetails.Gender}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",
                        // width: "max-content",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Permanent Address
                    </p>
                    <p className={Styles.title}>
                      Address :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData?.PersonalDetails?.AddressDetails?.Address}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      PinCode :{" "}
                      <span className={Styles.content}>
                      {clientData && clientData?.PersonalDetails?.AddressDetails?.Pincode}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Basic Info
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <div>
                        <p className={Styles.title}>
                          Mobile No :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.ClientDetails.MobileNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          PAN Number :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.ClientDetails.PANNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          DOB :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.ClientDetails.DateOfBirth?.slice(
                                0,
                                10
                              )}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Maritalstatus :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.MaritalStatus}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Father/SpouseName :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.FatherName}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Location :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.Location.Latitude} /{" "}
                            {clientData && clientData.Location.Longitude}
                          </span>
                        </p>
                      </div>

                      <div>
                        <p className={Styles.title}>
                          Trading Experience :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.ClientDetails.TradingExp}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          AnnualIncome :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.AnnualIncome}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Client Name :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails.ClientName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Email :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.ClientDetails.Email?.toLowerCase()}
                      </span>
                    </p>

                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Gender :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.PersonalDetails.Gender}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      MothersName :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.PersonalDetails.MotherName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails.Occupation}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation Others :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.ClientDetails.OccupationOthers}
                      </span>
                    </p>
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* -----------------------Bank------------------------------- */}

                {/* <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",

                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Bank Details
                    </p>
                    <div>
                      <p className={Styles.title}>
                        BankName :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          {clientBankData.BankName}
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          IFSC Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.IFSCCode}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Micr Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.Micr}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          AcNo :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.AcNo}
                          </span>
                        </p>
                      </div>

                      <div>
                        <p className={Styles.title}>
                          Name As In Bank :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.NameAsInBank}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <>
                  {clientData && clientData.BooleanValues.is_completed && (
                    <p className={Styles.title}>
                      Download Aadhaar XML :{" "}
                      <Tooltip title="Aadhaar XML Download">
                        <IconButton onClick={handleDownloadXml}>
                          <ArrowCircleDownIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                  )}

                  <div className={Styles.contentdiv}>
                    <div>
                      <p
                        className={Styles.title}
                        style={{
                          // textDecoration: "underline",
                          background: "#d9e4fd",
                          // width: "max-content",
                          padding: "3px 20px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        Aadhaar Details
                      </p>
                      <div>
                        <p className={Styles.title}>
                          Aadhaar Number : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData?.Aadhaar?.aadhaar_json?.maskedNumber}
                          </span>
                        </p>

                        <div>
                          <p className={Styles.title}>
                            Aadhaar DOB :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData?.Aadhaar?.aadhaar_json?.dateOfBirth}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            Aadhaar Name :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData?.Aadhaar?.aadhaar_json?.name}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            Aadhaar Gender :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData?.Aadhaar?.aadhaar_json?.gender}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            Aadhaar Address :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {clientData?.Aadhaar?.address && (
                              <span className={Styles.content}>
                                {clientData?.Aadhaar?.address}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                </>

                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>Nominee Details</p>
                    {nomineeDetails &&
                      nomineeDetails.map((data: any, index: any) => {
                        return (
                          <>
                            <p
                              className={Styles.title}
                              style={{
                                // textDecoration: "underline",
                                background: "#d9e4fd",
                                // width: "max-content",
                                padding: "3px 20px",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              Nominee {index + 1}
                            </p>
                            <div>
                              <p className={Styles.title}>
                                Name :{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className={Styles.content}>
                                  {" "}
                                  {data.FullName}
                                </span>
                              </p>

                              <div>
                                <p className={Styles.title}>
                                  RelationShip :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Relationship}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Relation Others : &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.RelationshipOthers}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Email :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Email?.toLowerCase()}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Mobile :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Mobile}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Share Percentage :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.SharePercentage}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  DOB :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.DOB}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Nominee ID Proof :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Nominee_Id_proof}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Nominee ID ProofNo : &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Nominee_Id}
                                  </span>
                                </p>
                              </div>

                              <div>
                                <p className={Styles.title}>
                                  Nominee Address :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Address}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Pincode :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Pincode}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  City :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.City}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  State :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.State}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian FullName:{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianFullName}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian DOB: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianDOB}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Email: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianEmail}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Address: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddress}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian City: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressCity}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian PinCode: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressPinCode}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian State: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressState}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Relation : &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianRealtionWithNominee}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <RekycBase64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {clientData && clientData.BooleanValues.is_deleted === false && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientData && clientData.BooleanValues.is_deleted && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />
              {/* {clientData && clientData.client_details.IsCompleted === "true" && ( */}
              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {/* {clientData.client_details.IsApproved === "false" && ( */}

                <ErrorSnackBar message={errorMessage} />

                {stageName !== "deleted" &&
                  stageName !== "rejected" &&
                  clientData?.BooleanValues?.is_rejected === false &&
                  clientData?.BooleanValues?.is_completed === true && (
                    <RekycReject
                      stagesData={stagesData}
                      handleRejectClient={handleRejectClient}
                      rejectLoading={rejectLoading}
                      disabled={false}
                    />
                  )}
                {isAdmin === "true" && (
                  <div>
                    {stageName !== "deleted" &&
                      stageName !== "rejected" &&
                      clientData?.BooleanValues?.is_completed === true &&
                      clientData?.BooleanValues?.is_approved === false &&
                      clientData?.BooleanValues?.is_rejected === false &&
                      stageName !== "approved" && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          disabled={false}
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                            "&:disabled": {
                              background: "#3cbc498c",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                {/* <div>
                  {stageName === "approved" &&
                    clientData &&
                    clientData.BooleanValues.is_aadhaar_fetched === true && (
                      <Button
                        type="submit"
                        onClick={handleKRAUpload}
                        loading={kraUploadLoading}
                      >
                        KRA Upload
                      </Button>
                    )}
                </div> */}
              </Stack>
              {/* )} */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
